import one from "../assets/one.jpg";
import two from "../assets/two.jpg";
import three from "../assets/three.jpg";
import four from "../assets/four.jpg";
import five from "../assets/five.jpg";
import six from "../assets/six.jpg";
import seven from "../assets/seven.jpg";
import eight from "../assets/eight.jpg";
import nine from "../assets/nine.jpg";
import ten from "../assets/ten.jpg";
import eleven from "../assets/eleven.jpg";
import twelve from "../assets/twelve.jpg";
import thirteen from "../assets/thirteen.jpg";
import fourteen from "../assets/fourteen.jpg";
import fifteen from "../assets/fifteen.jpg";
import sixteen from "../assets/sixteen.jpg";
import seventeen from "../assets/seventeen.jpg";
import eighteen from "../assets/eighteen.jpg";

const categories = [
  {
    id: 1,
    title: "Yirga Cheffe Coffee",
    description:
      "Produced in the Yirgacheffe region, this coffee is celebrated for its complex and distinct flavors, often characterized by floral and citrusy notes. It's highly prized by coffee enthusiasts worldwide.",
    coffees: [
      {
        id: 2,
        title: "Yirga Cheffe Washed Coffee Beans",
        link: "/Yirga-Cheffe/Washed-Coffee-Beans",
        description:
          "These beans undergo a meticulous washing process that involves removing the cherry pulp before drying the beans. This method often results in a cleaner cup with bright acidity and floral notes.",
        imageUrl: one,
      },

      {
        id: 4,
        title: "Yirga Cheffe Grade 1 Coffee Beans",
        link: "/Yirga-Cheffe/Grade-1-Coffee Beans",
        description:
          " Yirga Cheffe Grade 1 beans are typically the highest quality beans from the region, selected for their uniformity in size and shape, as well as their exceptional flavor profile. These beans often exhibit vibrant acidity, floral aromas, and pronounced fruitiness.",
        imageUrl: two,
      },
      {
        id: 5,
        title: "Yirga Cheffe Heirloom Varietals",
        link: "/Yirga-Cheffe/Heirloom-Varietals",
        description:
          "Yirga Cheffe is known for its diverse heirloom coffee varietals, which contribute to the region's unique flavor profile. These varietals may include local Ethiopian varieties such as Typica, Heirloom, and Kurume, each with its own distinct flavor characteristics.",
        imageUrl: three,
      },
      {
        id: 6,
        title: "Yirga Cheffe Organic Coffee Beans",
        link: "/Yirga-Cheffe/Organic-Coffee-Beans",
        description:
          " Many Yirga Cheffe coffee producers adhere to organic farming practices, avoiding synthetic fertilizers and pesticides. Organic Yirga Cheffe beans are often sought after for their environmentally friendly cultivation methods and clean, natural flavors.",
        imageUrl: four,
      },
    ],
  },
  {
    id: 2,
    title: "Harrar Coffee",
    description:
      "Grown in the eastern part of Ethiopia, particularly in the Harar region, this coffee is known for its bold and spicy flavors with winey undertones. It's often processed naturally, which contributes to its unique taste profile.",
    coffees: [
      {
        id: 8,
        title: "Harrar Natural Coffee Beans",
        link: "/Harrar/Natural-Coffee-Beans",
        description:
          " Harrar beans are often processed using the natural or dry method, where the coffee cherries are dried with the fruit intact. This process imparts a distinct fruity flavor to the beans, often with winey undertones and a heavy body.",
        imageUrl: five,
      },
      {
        id: 9,
        title: "Harrar Longberry Coffee Beans",
        link: "/Harrar/Longberry-Coffee-Beans",
        description:
          "Longberry is a classification based on the size of the coffee beans. Harrar Longberry beans are larger in size compared to their counterparts, often prized for their bold flavor and complex aroma. They may exhibit notes of spice, chocolate, and fruit.",
        imageUrl: six,
      },
      {
        id: 10,
        title: "Harrar Shortberry Coffee Beans",
        link: "/Harrar/Shortberry-Coffee-Beans",
        description:
          "Similar to Longberry, Harrar Shortberry beans are classified based on their size, being smaller compared to Longberry beans. Despite their smaller size, Shortberry beans can still offer intense flavors and aromas characteristic of Harrar coffee, with a slightly different profile.",
        imageUrl: seventeen,
      },
      {
        id: 11,
        title: "Harrar Mocha Coffee Beans",
        link: "/Harrar/Mocha-Coffee-Beans",
        description:
          "Harrar Mocha beans are a unique variety that includes a mix of smaller-sized beans and peaberries (beans that develop as a single seed inside the coffee cherry). Mocha beans are known for their rich, chocolaty flavor profile and may have fruity undertones, making them a favorite among coffee enthusiasts.",
        imageUrl: eight,
      },
      {
        id: 12,
        title: "Harrar Grade 4 Coffee Beans",
        link: "/Harrar/Grade-4-Coffee-Beans",
        description:
          " Harrar Grade 4 beans are typically a lower grade compared to higher-quality grades like Grade 1 or Grade 2. However, they still offer the distinctive Harrar flavor profile, albeit with potential variations in size, uniformity, and defects.",
        imageUrl: nine,
      },
      {
        id: 13,
        title: "Harrar Espresso Roast Coffee Beans",
        link: "/Harrar/Espresso-Roast-Coffee-Beans",
        description:
          "Some Harrar beans are specifically roasted for espresso brewing. Harrar espresso roast beans are often dark-roasted to bring out bold flavors and intense aromas, making them suitable for espresso-based drinks like lattes and cappuccinos.",
        imageUrl: ten,
      },
    ],
  },
  {
    id: 3,
    title: "Limu Coffee",
    description:
      "Grown in the Limu region, this coffee is characterized by its medium body, balanced acidity, and nutty or chocolaty flavor notes. It's often considered a versatile coffee suitable for various brewing methods.",
    coffees: [
      {
        id: 14,
        title: "Limu Washed Coffee Beans",
        link: "/Limu/Washed-Coffee-Beans",
        description:
          "Limu beans processed using the washed method undergo meticulous washing to remove the cherry pulp before drying. This process typically results in a clean cup with balanced acidity and nuanced flavor notes, such as floral, citrusy, and nutty undertones.",
        imageUrl: eleven,
      },
      {
        id: 15,
        title: "Limu Natural Coffee Beans",
        link: "/Limu/Natural-Coffee-Beans",
        description:
          " Natural or dry-processed Limu beans are dried with the cherry still intact, allowing the sugars from the fruit to infuse the beans during the drying process. This method often yields a sweeter cup with pronounced fruity flavors, along with a heavier body and lower acidity.",
        imageUrl: twelve,
      },
      {
        id: 16,
        title: "Limu Grade 1 Coffee Beans",
        link: "/Limu/Grade-1-Coffee-Beans",
        description:
          " Limu Grade 1 beans are among the highest quality beans from the region, selected for their uniform size, shape, and defect-free appearance. These beans typically exhibit vibrant acidity, complex flavor profiles, and a clean, lingering finish.",
        imageUrl: thirteen,
      },
      {
        id: 17,
        title: "Limu Heirloom Varietals",
        link: "/Limu/Heirloom-Varietals",
        description:
          " Limu is known for its diverse heirloom coffee varietals, including local Ethiopian varieties such as Typica, Heirloom, and JARC selections. Each varietal contributes unique flavor characteristics to Limu coffee, ranging from fruity and floral to nutty and chocolaty.",
        imageUrl: fourteen,
      },
      {
        id: 18,
        title: "Limu Organic Coffee Beans",
        link: "/Limu/Organic-Coffee-Beans",
        description:
          " Many Limu coffee producers adhere to organic farming practices, avoiding synthetic fertilizers and pesticides. Organic Limu beans are often sought after for their environmentally friendly cultivation methods and clean, natural flavors, reflecting the terroir of the region.",
        imageUrl: fifteen,
      },
      {
        id: 19,
        title: "Limu Medium Roast Coffee Beans",
        link: "/Limu/Medium-Roast-Coffee-Beans",
        description:
          " Medium-roasted Limu beans offer a balanced flavor profile with moderate acidity and a smooth, mellow body. This roast level preserves the nuanced flavors of Limu coffee while imparting subtle caramelization notes from the roasting process.",
        imageUrl: sixteen,
      },
    ],
  },
];

export default categories;

import React from "react";

const AwardsComponent = () => {
  const awards = [
    {
      id: 1,
      title: "Best Coffee Importer of the Year",
      reason: "Exceptional sourcing and quality control",
      description:
        "Cerera Coffee Import Export was awarded the prestigious 'Best Coffee Importer of the Year' for its outstanding dedication to sourcing premium coffee beans from around the world and ensuring unmatched quality standards.",
    },
    {
      id: 2,
      title: "Innovation in Coffee Packaging",
      reason: "Revolutionary packaging solutions",
      description:
        "Cerera Coffee Import Export received the 'Innovation in Coffee Packaging' award for its pioneering approach to coffee packaging, enhancing freshness and sustainability while delighting customers with visually appealing designs.",
    },
    {
      id: 3,
      title: "Community Impact Award",
      reason: "Positive impact on coffee-producing communities",
      description:
        "Recognized for its commitment to ethical sourcing and community development, Cerera Coffee Import Export was honored with the 'Community Impact Award' for its contributions to improving the livelihoods of coffee farmers and their communities.",
    },
  ];

  return (
    <div className="container mx-auto px-4 lg:px-8 bg-darkbrown-light">
      <h2 className="font-bold text-2xl text-gray-800">Awards</h2>
      <hr className="my-4 border-gray-600 sm:mx-auto dark:border-gray-700" />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {awards.map((award) => (
          <a
            key={award.id}
            href="#"
            className="block p-6 lg:p-10 bg-primary border border-gray-500 rounded-lg shadow-md hover:bg-secondary dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
          >
            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-200 dark:text-white">
              {award.title}
            </h5>
            <hr className="my-4 border-gray-400 sm:mx-auto dark:border-gray-700" />
            <p className="font-normal text-sm text-gray-300 dark:text-gray-400">
              {award.description}
            </p>
          </a>
        ))}
      </div>
    </div>
  );
};

export default AwardsComponent;

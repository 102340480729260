import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import About from "./pages/About";
import Products from "./pages/Products";
import CardDetail from "./pages/card/CardDetail";
import ContactForm from "./pages/ContactForm";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Products />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/coffee/:id" element={<CardDetail />} />
      </Routes>
      <Toaster />
    </div>
  );
}

export default App;

import React from "react";
import { useParams } from "react-router-dom";
import categories from "../../data/categories";
import SimilarCards from "./SimilarCards";
import MainLayout from "../../components/MainLayout";

const CardDetail = () => {
  const { id } = useParams();

  const coffee = categories
    .flatMap((category) => category.coffees)
    .find((coffee) => coffee.id === parseInt(id));

  if (!coffee) {
    return (
      <div className="text-center mt-8 text-red-500">Product not found</div>
    );
  }

  const category = categories.find((category) =>
    category.coffees.some((c) => c.id === coffee.id)
  );

  const similarCards = categories
    .flatMap((category) => category.coffees)
    .filter((c) => c.id !== coffee.id)
    .slice(0, 8);

  return (
    <MainLayout>
      <div className="bg-primary">
        <section className="container mx-auto max-w-5xl flex flex-col lg:flex-row px-4 py-8 md:px-8 gap-5 lg:items-start">
          <div className="flex-1">
            <div className=" max-w-lg mx-auto rounded-lg">
              <h2 className="text-2xl font-semibold text-white mb-3">
                {coffee.title}
              </h2>
              <hr className="my-4 border-gray-400 sm:mx-auto dark:border-gray-700" />
              <img
                src={coffee.imageUrl}
                alt={coffee.title}
                className="rounded-lg w-full mb-4"
              />
              <div>
                <p className="text-gray-300 mt-2">{coffee.description}</p>
              </div>
            </div>
          </div>
          <div className="flex-1">
            {similarCards && (
              <SimilarCards
                originalSimilarCards={similarCards}
                className="lg:ml-20"
              />
            )}
          </div>
        </section>
        <div className="bg-darkbrown-light">
          <div className="container mx-auto px-4 py-8 md:px-8">
            <h2 className="font-bold text-2xl text-buttons">
              {category.title}
            </h2>
            <hr className="my-4 border-gray-700 sm:mx-auto dark:border-gray-700" />
            <p className="text-gray-600">{category.description}</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default CardDetail;

import React, { useEffect, useState } from "react";
import categories from "../../../data/categories";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const CardSkeleton = () => {
  return (
    <div className="leaf-card relative overflow-hidden animate-pulse">
      <div
        className="bg-lime-50 pb-3 relative"
        style={{
          height: "200px",
          borderTopRightRadius: "80px",
          borderBottomLeftRadius: "80px",
          overflow: "hidden",
        }}
      >
        <h3 className="absolute bottom-0 leftt-0 w-full bg-secondary bg-opacity-80 px-4 py-2 text-sm font-semibold text-gray-200 dark:text-white">
          <div className="flex flex-col items-end">Loading...</div>
        </h3>
        <div className="absolute bottom-0 left-0 w-full bg-secondary bg-opacity-80 px-4 pb-10 pt-2">
          <p className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-blue-500 rounded-lg hover:text-blue-400 gap-x-2">
            Read more
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </p>
        </div>
      </div>
    </div>
  );
};

const Sidebar = ({ displayAll }) => {
  const [selectedCategory, setSelectedCategory] = useState(categories[1].id);
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };
  const displayedCoffees = categories
    .find((category) => category.id === selectedCategory)
    ?.coffees.slice(0, displayAll ? categories.length : 3);

  return (
    <div className="bg-primary">
      <div className="container mx-auto py-3 md:py-5 flex flex-col">
        <div className="px-6 font-bold text-gray-200 text-sm md:text-md mt-5 mb-3 md:mb-5">
          Welcome to <span className="text-buttons">Cerera Coffee</span> , your
          destination for exquisite coffee experiences.
        </div>
        <hr className="my-2 border-gray-400 sm:mx-auto dark:border-gray-700 md:hidden" />
        <div className="flex flex-col md:flex-row items-start justify-start md:items-center md:justify-center py-4 px-4">
          <div className="flex flex-col md:flex-row items-start justify-start md:items-center md:space-x-10">
            {categories.map((category) => (
              <div key={category.id} className="relative">
                {/* Render icon on small screens */}
                <FaArrowRight className="absolute left-0 top-1/2 transform -translate-y-1/2 text-buttons md:hidden" />
                <button
                  className={`text-gray-100 md:text-gray-700 md:bg-darkbrown-light bg-opacity-60 px-6 py-2 md:rounded-full font-bold hover:bg-opacity-80 hover:text-buttons md:hover:border-b-4 hover:border-b-buttons focus:outline-none ${
                    selectedCategory === category.id
                      ? "text-buttons md:border-b-4 md:border-b-buttons"
                      : ""
                  }`}
                  onClick={() => handleCategoryClick(category.id)}
                >
                  {category.title}
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="p-6">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            {displayedCoffees &&
              displayedCoffees.map((coffee, index) => (
                <Link
                  to={`/coffee/${coffee.id}`}
                  key={index}
                  onMouseEnter={() => setHoveredCardIndex(index)}
                  onMouseLeave={() => setHoveredCardIndex(null)}
                >
                  {/* Render CardSkeleton based on isLoading state */}
                  {isLoading ? (
                    <CardSkeleton />
                  ) : (
                    <div
                      className="leaf-card relative overflow-hidden"
                      style={{ height: "200px" }}
                    >
                      {/* Your actual card content */}
                      <div
                        className="bg-lime-50 pb-3 relative"
                        style={{
                          backgroundImage: `url(${coffee.imageUrl})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          height: "100%",
                          borderTopRightRadius: "80px",
                          borderBottomLeftRadius: "80px",
                          overflow: "hidden",
                        }}
                      >
                        <h3 className="absolute bottom-0 leftt-0 w-full bg-secondary bg-opacity-80 px-4 py-2 text-sm font-semibold text-gray-200 dark:text-white">
                          <div className="flex flex-col items-end">
                            {coffee.title}
                          </div>
                        </h3>
                        {hoveredCardIndex === index && (
                          <div className="absolute bottom-0 left-0 w-full bg-secondary bg-opacity-80 px-4 pb-10 pt-2 transition-transform duration-500 transform  hover:translate-y-0">
                            <p className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-blue-500 rounded-lg hover:text-blue-400 gap-x-2">
                              Read more
                              <svg
                                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 10"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M1 5h12m0 0L9 1m4 4L9 9"
                                />
                              </svg>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Link>
              ))}
          </div>
          {!displayAll && (
            <div className="mt-10 md:mt-16 flex flex-col justify-center items-center">
              <Link
                to="/products"
                className="flex relative z-20 items-center gap-x-2 font-bold bg-buttons text-white border-2 border-buttons px-6 py-1 rounded-lg hover:bg-orange-400"
              >
                <span>View all products</span>
                <FaArrowRight className="w-3 h-3" />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

import React from "react";
import HeroPage from "./container/HeroPage";
import Aboutus from "./container/Aboutus";
import Sidebar from "./container/Sidebar";
import "../../index.css";
import Footer from "../../components/Footer";

const HomePage = () => {
  return (
    <div className="bg-primary">
      <HeroPage />
      <Sidebar />
      <Aboutus />
      <Footer />
    </div>
  );
};

export default HomePage;

import React, { useEffect, useState } from "react";
import categories from "../data/categories";
import { Link } from "react-router-dom";
import MainLayout from "../components/MainLayout";
import coffee from "../assets/hands.jpg";
import farm from "../assets/coffeefam.jpg";
import { FaArrowRight } from "react-icons/fa";

const CardSkeleton = () => {
  return (
    <div className="leaf-card relative overflow-hidden animate-pulse">
      <div
        className="bg-lime-50 pb-3 relative"
        style={{
          height: "200px",
          borderTopRightRadius: "80px",
          borderBottomLeftRadius: "80px",
          overflow: "hidden",
        }}
      >
        <h3 className="absolute bottom-0 leftt-0 w-full bg-secondary bg-opacity-80 px-4 py-2 text-sm font-semibold text-gray-200 dark:text-white">
          <div className="flex flex-col items-end">Loading...</div>
        </h3>
        <div className="absolute bottom-0 left-0 w-full bg-secondary bg-opacity-80 px-4 pb-10 pt-2">
          <p className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-blue-500 rounded-lg hover:text-blue-400 gap-x-2">
            Read more
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </p>
        </div>
      </div>
    </div>
  );
};

const Products = () => {
  const [selectedCategory, setSelectedCategory] = useState(categories[1].id);
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };
  const scrollToProducts = () => {
    const productsSection = document.getElementById("products");
    if (productsSection) {
      productsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <MainLayout>
      <div className="bg-primary">
        <div className="container mx-auto px-4 py-8 md:px-8">
          <div className="flex flex-col justify-center md:justify-between md:flex-row">
            <div className="md:w-1/2 mt-5">
              <img src={coffee} alt="" className="w-full object-cover" />
            </div>

            <div className="md:w-1/2 bg-darkbrown-light rounded-md bg-opacity-80 mt-10 p-5 lg:p-10 flex flex-col items-center md:items-start text-gray-200">
              <h1 className="text-3xl lg:text-4xl font-bold mb-3 md:mb-5 text-darkbrown-eerie font-pacifico">
                Discover Our Coffee Selection
              </h1>
              <p className="text-sm font-bold leading-relaxed lg:mb-4 text-darkbrown-eerie pr-3">
                From the lush coffee fields of Cerera to your cup, experience
                the finest selection of hand-picked beans, expertly roasted to
                perfection. Whether you prefer a rich espresso or a smooth
                latte, we have a blend that will tantalize your taste buds.
              </p>
              <p className="text-sm mb-5 text-darkbrown-eerie font-bold">
                Dive into our collection and awaken your senses with the aroma
                and flavor of Cerera Coffee.
              </p>

              <Link
                to="#"
                onClick={scrollToProducts}
                className="px-6 py-3 bg-buttons text-white rounded-lg hover:bg-orange-400 self-start"
              >
                View Products
              </Link>
            </div>
          </div>
          <div className="font-bold text-gray-200 text-lg mt-10 mb-3 md:hidden">
            Our Coffee Products
          </div>
          <hr className="my-1 border-gray-400 sm:mx-auto dark:border-gray-700 md:hidden" />
          <div className="flex flex-col md:flex-row items-start justify-start md:items-center md:justify-center py-3 md:mt-10 mb-4">
            <div className="flex flex-col md:flex-row items-start justify-start md:items-center md:space-x-10">
              {categories.map((category) => (
                <div key={category.id} className="relative">
                  {/* Render icon on small screens */}
                  <FaArrowRight className="absolute left-0 top-1/2 transform -translate-y-1/2 text-buttons md:hidden" />
                  <button
                    className={`text-gray-100 md:text-gray-700 md:bg-darkbrown-light bg-opacity-60 px-6 py-2 md:rounded-full font-bold hover:bg-opacity-80 hover:text-buttons md:hover:border-b-4 hover:border-b-buttons focus:outline-none ${
                      selectedCategory === category.id
                        ? "text-buttons md:border-b-4 md:border-b-buttons"
                        : ""
                    }`}
                    onClick={() => handleCategoryClick(category.id)}
                  >
                    {category.title}
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className="md:mt-10" id="products">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              {isLoading
                ? Array.from({ length: 3 }).map((_, index) => (
                    <CardSkeleton key={index} />
                  ))
                : categories
                    .find((category) => category.id === selectedCategory)
                    ?.coffees.map((coffee, index) => (
                      <Link
                        to={`/coffee/${coffee.id}`}
                        key={index}
                        onMouseEnter={() => setHoveredCardIndex(index)}
                        onMouseLeave={() => setHoveredCardIndex(null)}
                      >
                        {/* Render CardSkeleton conditionally */}
                        {isLoading && index < 3 ? (
                          <CardSkeleton />
                        ) : (
                          <div
                            className="leaf-card relative overflow-hidden"
                            style={{ height: "200px" }}
                          >
                            {/* Your actual card content */}
                            <div
                              className="bg-lime-50 pb-3 relative"
                              style={{
                                backgroundImage: `url(${coffee.imageUrl})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                height: "100%",
                                borderTopRightRadius: "80px",
                                borderBottomLeftRadius: "80px",
                                overflow: "hidden",
                              }}
                            >
                              <h3 className="absolute bottom-0 leftt-0 w-full bg-secondary bg-opacity-80 px-4 py-2 text-sm font-semibold text-gray-200 dark:text-white">
                                <div className="flex flex-col items-end">
                                  {coffee.title}
                                </div>
                              </h3>
                              {hoveredCardIndex === index && (
                                <div className="absolute bottom-0 left-0 w-full bg-secondary bg-opacity-80 px-4 pb-10 pt-2 transition-transform duration-500 transform  hover:translate-y-0">
                                  <p className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-blue-500 rounded-lg hover:text-blue-400 gap-x-2">
                                    Read more
                                    <svg
                                      className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 14 10"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M1 5h12m0 0L9 1m4 4L9 9"
                                      />
                                    </svg>
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </Link>
                    ))}
            </div>
          </div>

          <div className="relative mt-8 md:mt-16">
            <div className="absolute inset-0 flex flex-col justify-center items-center bg-[hsla(0,0%,0%,0.55)] px-4 lg:px-32">
              <h1 className="text-2xl lg:text-4xl text-gray-200 font-bold">
                Our Farm in Ethiopia
              </h1>
              <p className="text-sm md:text-md font-semibold md:bg-primary md:bg-opacity-70 md:rounded-md md:py-5 md:px-3 text-gray-200 mt-3 md:mt-5">
                Nestled in the heart of Ethiopia, our farm is a sanctuary where
                every coffee bean is nurtured to perfection. Situated amidst
                breathtaking landscapes, our farm embraces sustainable farming
                practices to produce the finest coffee beans. With a dedication
                to quality and excellence, we meticulously cultivate and harvest
                our beans, ensuring that each cup of Cerera Coffee embodies the
                rich flavors and unique characteristics of Ethiopian coffee.
                Join us on a journey to discover the essence of Ethiopian
                coffee, handcrafted with care on our cherished farm.
              </p>
            </div>
            <img src={farm} alt="" className="w-full h-[400px] object-cover" />
          </div>

          <div className="mt-10">
            <h2 className="font-bold text-2xl text-white">Production</h2>
            <hr className="my-4 border-gray-400 sm:mx-auto dark:border-gray-700" />
          </div>
          <div className="flex flex-col justify-start items-start py-3 mt-4">
            <ol className="flex flex-col space-y-4">
              <li className="text-gray-200 font-semibold text-sm md:text-md">
                <span className="text-lg font-bold text-buttons mr-2">
                  Washing Station
                </span>
                Under the name of our company and owner's name, we own 16
                washing stations and 4 dry mills in the Bensa, Aroressa, and
                Chire districts of Sidama.
              </li>
              <li className="text-gray-200 font-semibold text-sm md:text-md ">
                <span className="text-lg font-bold text-buttons mr-2">
                  Export Facilities
                </span>
                We provide warehouse facilities both in Addis Ababa and Hawassa
                cities. Three warehouses with a total capacity of 4400 square
                meters are located in Hawassa and five in Addis Ababa with a
                capacity of 5000 sq. m where our modern export coffee cleaning
                plant is situated.
              </li>
              <li className="text-gray-200 font-semibold text-sm md:text-md">
                <span className="text-lg font-bold text-buttons mr-2">
                  Coffee Cleaning
                </span>
                Coffee Cleaning Plant We own a coffee cleaning and processing
                plant with pre-cleaner, de-stoner, huller, polisher, grader, and
                handpicking belt facilities with a 4ton/hr capacity. Our color
                sorter from SATAKE is of the latest camera technology to sort
                coffee beans with higher sensitivity to remove rejects with
                maximum efficiency. In order to meet the demand for bulk packing
                from our customers we use a pneumatic system to load containers
                in bulk.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Products;

import React from "react";
import videoBg from "../../../assets/background.mp4";
import HeroHeader from "./HeroHeader";
import { Link } from "react-router-dom";

const HeroPage = () => {
  return (
    <>
      <div className="relative h-[500px]">
        <div className="absolute top-0 left-0 w-full h-full z-10"></div>
        <HeroHeader />
        <video
          className="absolute inset-0 w-full h-full object-cover -z-1"
          src={videoBg}
          autoPlay
          loop
          muted
          playsInline
        />
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-[hsla(0,0%,0%,0.55)] z-20">
          <div className="flex h-full items-center justify-center">
            <div className="px-6 text-center  md:px-12">
              <h1 className="mt-6 mb-4 md:mb-9 text-3xl font-bold tracking-tight md:text-4xl xl:text-5xl text-gray-300">
                The best offer on the market <br />
                <span className="text-gray-400">for your business</span>
              </h1>
              <Link
                to="/products"
                className="mb-2 inline-block rounded-full border-2 border-neutral-50 px-[46px] pt-[14px] pb-[12px] text-sm font-medium uppercase leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200 md:mr-2 md:mb-0"
              >
                Explore more
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroPage;

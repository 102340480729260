import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/logo1.svg";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

const HeroHeader = () => {
  const location = useLocation();
  const [navIsVisible, setNavIsVisible] = useState(false);

  const navVisibilityHandler = () => {
    setNavIsVisible((curState) => !curState);
  };

  const navItems = [
    { name: "Home", href: "/" },
    { name: "About", href: "/about" },
    { name: "Our Coffee", href: "/products" },
    { name: "Contact", href: "/contact" },
  ];

  const leftNavItems = navItems.slice(0, 2);
  const rightNavItems = navItems.slice(2);

  return (
    <nav className="absolute top-0 left-0 w-full border-gray-200 z-50">
      <div className="max-w-screen-xl mx-auto p-4">
        <div className="flex items-center justify-between lg:justify-center">
          <div className="flex items-center space-x-10">
            <div className="hidden lg:flex items-center space-x-10">
              {leftNavItems.map((item, index) => (
                <Link
                  key={index}
                  to={item.href}
                  className={`py-2 px-3 rounded text-lg font-bold hover:border-b-2 hover:border-b-blue-500 hover:text-blue-500 ${
                    location.pathname === item.href
                      ? "text-blue-500"
                      : "text-gray-100"
                  }`}
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <Link
              to="/"
              className="flex items-center space-x-3 rtl:space-x-reverse"
            >
              <img src={logo} alt="logo" className="w-20 lg:w-auto" />
            </Link>
            <div className="hidden lg:flex items-center space-x-10">
              {rightNavItems.map((item, index) => (
                <Link
                  key={index}
                  to={item.href}
                  className={`py-2 px-3 rounded text-lg font-bold hover:border-b-2 hover:border-b-blue-500 hover:text-blue-500 ${
                    location.pathname === item.href
                      ? "text-blue-500"
                      : "text-gray-100"
                  }`}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
          <div className="lg:hidden">
            {navIsVisible ? (
              <AiOutlineClose
                className="w-6 h-6 cursor-pointer text-white"
                onClick={navVisibilityHandler}
              />
            ) : (
              <AiOutlineMenu
                className="w-6 h-6 cursor-pointer text-white"
                onClick={navVisibilityHandler}
              />
            )}
          </div>
        </div>
      </div>
      {navIsVisible && (
        <div className="lg:hidden bg-secondary p-4">
          {navItems.map((item, index) => (
            <Link
              key={index}
              to={item.href}
              className={`block py-2 px-3 rounded text-lg font-bold hover:border-b-2 hover:border-b-blue-500 hover:text-blue-500 ${
                location.pathname === item.href
                  ? "text-blue-500"
                  : "text-gray-200"
              }`}
            >
              {item.name}
            </Link>
          ))}
        </div>
      )}
    </nav>
  );
};

export default HeroHeader;
